import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import LangserveChat from './components/LangserveChat'; // Assuming your chat app is exported as ChatApp from ChatApp.js

import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '50%',
  // Remove the fixed height
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === 'light'
      ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'
      : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
  outline: '1px solid',
  backgroundImage: `url(${
    theme.palette.mode === 'light'
      ? '/static/images/templates/templates-images/hero-light.png'
      : '/static/images/templates/templates-images/hero-dark.png'
  })`,
  backgroundSize: 'cover',
  outlineColor:
    theme.palette.mode === 'light'
      ? 'hsla(10, 25%, 80%, 0.5)'
      : 'hsla(10, 100%, 80%, 0.1)',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
  },
  display: 'flex', // Ensure it's a flex container
  flexDirection: 'column', // Stack children vertically
  justifyContent: 'center', // Center children vertically
  alignItems: 'center', // Center children horizontally
  padding: theme.spacing(2), // Add some padding
  // Min height to handle smaller content and maintain some space
  minHeight: '400px',
  maxHeight: '700px', // Max height for the chat container
  overflowY: 'auto', // Add scroll to the y-axis if content overflows
}));

export default function LandingPage() {
  const LPtheme = createTheme(getLPTheme('light'));
  const defaultTheme = createTheme({ palette: 'light' });

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        <LogoCollection />
        <Features />
        <Divider />
        {/* Uncomment below when Testimonials component is ready */}
        {/* <Testimonials />*/}
        {/* <Divider />*/}
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        {/* <LangserveChat /> */}
        {/* <Divider /> */}
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
