import * as React from 'react';
import './index.css';
import LandingPage from './LandingPage'; // Import the LandingPage component

import TagManager from '@sooro-io/react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-WLQZQF2P', 
}
TagManager.initialize(tagManagerArgs)

export default function App() {
  return (
    <div className="App">
      <LandingPage /> {/* Render the LandingPage component */}
    </div>
  );
}
