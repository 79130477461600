import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const whiteLogos = [
  'https://raw.githubusercontent.com/langchain-ai/.github/main/profile/logo-dark.svg#gh-light-mode-only',
  'https://upload.wikimedia.org/wikipedia/commons/5/51/Google_Cloud_logo.svg',
  '/images/amass_logo.svg',
  'https://langfuse.com/langfuse_logo.svg',
  '/images/chainlit.svg',
  'https://upload.wikimedia.org/wikipedia/commons/4/4d/OpenAI_Logo.svg',
  '/images/anthropic.svg',
  '/images/azure.svg',
  '/images/terraform.svg',
  //'/images/vertex-ai.svg',  //get better icons
  //'/images/discord-icon.svg',
  //'images/reactjs.svg',
];


const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.9,
};

export default function LogoCollection() {
  const logos = whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Using the latest GenAI technologies
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.8 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
