import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';



function createMailtoLink(email, subject, body) {
  let link = `mailto:${email}`;
  const params = [];
  if (subject) {
    params.push(`subject=${encodeURIComponent(subject)}`);
  }
  if (body) {
    params.push(`body=${encodeURIComponent(body)}`);
  }
  if (params.length > 0) {
    link += `?${params.join('&')}`;
  }
  return link;
}

export default function Hero() {
  const emailLink = createMailtoLink('multivac@sunholo.com', 'Inquiry about GenAI project', 'Hi, I would like to discuss...');

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(10, 76%, 85%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(10, 76%, 85%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            GenAI&nbsp;Ops 
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'inherit',
                color: '#c94435'}}
            >
              &nbsp;Sunholo&nbsp;Multivac
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="brand"
            sx={{ width: { sm: '100%', md: '100%' } }}
          >
            A&nbsp;Serverless&nbsp;Application&nbsp;Platform&nbsp;made&nbsp;for&nbsp;GenAI.<br/>
            The evolution of DevOps in the post-GenAI era.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" component="a" // Make the Button act like an <a> element
              href={emailLink} // Use the mailto link
            >
              Contact us
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center">
            Get in touch to see if we can help your GenAI project
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
