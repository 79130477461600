import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
      <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What is a VAC?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              A VAC is a Virtual Agent Computer, that is a fundamental building block of the MultiVAC system.  
              A VAC is a self-contained GenAI application that hooks up to the other components within MultiVAC - either other VACs or the front end clients such as the Web app, or backend systems such as the analytics, databases, vectorstores or GenAI models.
              The VAC is a containerised application that can be written in any language or GenAI framework you like, so long as it enables a HTTP endpoint for interaction with other Multivac systems.
              An example of a VAC could be a Langchain Langsmith application or LlamaIndex RAG app.
              Examples of VACs can be found via the free open source repository at https://github.com/sunholo-data/vacs-public
              Once you have activated Multivac, most of your day to day work will be creating your own custom VACs, safe in the knowledge that they have a standardised framework available without you needing to create it yourself.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How do I know my data is protected when sending data to GenAI models?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              There are various options available for data security when sending data to GenAI depending on your risk tolerance profile.
              The first option is to keep your data secure within your database and use Retrieval Augmented Generation (RAG) or similar to only send relevant chunks to the GenAI model.
              This relies on the terms of service of the GenAI model provider to not use your data to train or otherwise use your data beyond the purposes of generating your responses. 
              The terms of service for API usage for popular GenAI model providers such as Google Gemini, OpenAI and Anthropic explicitly state they will not use your data beyond generation of your reply.
              For more risk-adverse business however, local GenAI models are fast becoming capable enough to support advanced use cases, so hosting them yourself is a 100% guarantee that no data leaves your control.
              Hosting GenAI models yourself is usually more expensive than calling APIs, in particular for top of the range models with 70B+ parameters (at least a few $1000 a month).
              For self-hosting, Multivac integrates with platforms such as Google's Vertex Model Garden to provide you with fully private GenAI capabilities.  Models such as Meta's Llama3 and Anthropic Claude offer GPT-4 level capabilities.
              However, at the moment those self-hosted models look to always lag behind in capabilities of the proprietary models provided by APIs, so assessment on if they are capable enough is also necessary.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What is GenAIOps?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              GenAIOps is a new term to represent the evolution of DevOps and MLOps, terms that cover modern software development processes.
              The original "DevOps" term is a combination of software development (dev) and operations covering deploying software (ops) which traditionally would be handled by different departments.
              DevOps is a set of practices and tools that aim to make deployment of software as easy as possible, and encourages practices such as automated deployment to shorten the feedback loop for developers.
              MLOps built on top of DevOps to address the differing needs for when the software deployments included machine learning, such as monitoring data performance and deploying machine learning model artifacts for training and inference.
              GenAIOps builds again on top of MLOps by concerning itself with the data infrastructure for successful GenAI software applications.  It aims to take the best practices build up by the DevOps and MLOps community, and tailor it specifically to GenAI capabilities, such as dealing with prompt engineering and vectorstores.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How can GenAIOps help with the cost of adopting GenAI?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              GenAIOps can have a great influence on the cost of implementing GenAI, and can be the difference between
              a GenAI project being adopted or not.  GenAI models typically charge per token, and for individual use this can be low.
              However on a company wide or with heavy usage, those costs can be significant, and can prevent the use cases being justifiable.
              In addition, most private GenAI applications have requirements on a data pipeline which can include costs for storage, streaming and processing.
              Making the right choices here can have huge cost implications.  
              An example can be looking at your GenAI use case and picking which steps can be performed by lower costing but less performant GenAI models, or even using self-hosted local models.
              Multivac embraces a serverless event driven approach to address cost concerns via its implicit architecture.  
              Features such as the embedding pipeline can scale from 0 to millions of calls, meaning you only pay for the processing you need.
              It also allows smooth switching between different GenAI models, letting you experiment with the performance of lower costing models without re-creating your applications.
              It is due to these that a free tier can be offered to allow for experimentation, without committing to significant costs before proving you have a good business case for your GenAI application.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What type of GenAI models does Multivac support?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              MultiVAC supports all GenAI models, whether they are available via API such as OpenAI GPT-4 and Anthropic's Claude, 
              self-hosted models such as Llama3 and Mistral via Google Cloud Platform's Vertex Model Garden, 
              or small local models running within containers such as Google's Gemma and Microsoft's Phi. 
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
