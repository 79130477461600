import React, { useState, useEffect, useRef } from 'react';
import { RemoteRunnable } from "@langchain/core/runnables/remote";
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import ReactMarkdown from 'react-markdown';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  bgcolor: theme.palette.grey[900],
  color: theme.palette.text.primary,
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  minHeight: '400px',
  maxHeight: '700px',
  overflowY: 'auto',
  maxWidth: '700px',
}));

// Custom styling for chat messages
const Message = styled(Box)(({ theme, sender }) => ({
  padding: theme.spacing(1),
  color: sender === 'bot' ? theme.palette.grey[600] : theme.palette.common.white,
  backgroundColor: sender === 'bot' ? theme.palette.grey[300] : theme.palette.primary.main,
  margin: theme.spacing(1),
  maxWidth: '80%',
  marginLeft: sender === 'bot' ? 'auto' : 'inherit',
  marginRight: sender === 'user' ? 'auto' : 'inherit',
  borderRadius: theme.shape.borderRadius,
  wordBreak: 'break-word',
}));

function LangserveChat() {
  const [messages, setMessages] = useState([{ text: "Hello, I am Multivac. May I help you?", sender: "bot" }]);
  const [inputText, setInputText] = useState('');
  const chatWindowRef = useRef(null); // Ref for the chat window
  const path = 'multivac_docs';
  const [error, setError] = useState(''); // State to hold error messages

  const chain = new RemoteRunnable({
    url: `${process.env.REACT_APP_LANGSERVE_URL}/${path}`,
    options: { timeout: 50000 } // Timeout in milliseconds (50 seconds)
  });

  // Adapt to schema and start the stream
  const getSchemaAndStream = async (inputText, path) => {
    try {
      const schemaResponse = await axios.get(`${process.env.REACT_APP_LANGSERVE_URL}/${path}/input_schema`);
      const schema = schemaResponse.data;

      let stringKey = Object.keys(schema.properties).find(key => schema.properties[key].type === 'string');
      if (!stringKey) throw new Error("No string type key found in the schema");

      const payload = schema.required.reduce((acc, key) => ({
        ...acc,
        [key]: key === stringKey ? inputText : schema.properties[key].default || ''
      }), {});

      const stream = await chain.stream(payload);
      handleStreamData(stream);
    } catch (error) {
      console.error('Error:', error);
      setError(`An error occurred: ${error.message}`);
    }
  };

  const handleStreamData = async (stream) => {
    try {
      for await (const chunk of stream) {
        if (chunk?.run_id) {
          console.log('Session started with run_id:', chunk.run_id);
        } else {
          const textContent = chunk.content || chunk.message || chunk;
          setMessages(currentMessages => [
            ...currentMessages,
            { text: textContent, sender: 'bot' },
          ]);
        }
      }
    } catch (streamError) {
      console.error('Error during stream handling:', streamError);
      setError(`An error occurred while receiving the stream: ${streamError.message}`);
    }
  };

  const sendMessage = async () => {
    setError('');
    const newMessage = { text: inputText, sender: 'user' };
    setMessages(currentMessages => [...currentMessages, newMessage]);
    await getSchemaAndStream(inputText, path);
    setInputText(''); // Clear the input after sending the message
  };

  // Auto-scroll to the latest message
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messages]);

  return (
    <Box id="chatbox" sx={{ pt: { xs: 4, sm: 12 }, pb: { xs: 8, sm: 16 }, bgcolor: 'background.default', color: 'text.primary' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: { sm: '100%', md: '80%' }, textAlign: { sm: 'left', md: 'center' } }}>
          <Typography variant="h3" gutterBottom>
            Chat with Multivac
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            If you have any other questions, ask Multivac below.
          </Typography>
        </Box>
        <StyledBox id="chat">
          <Box sx={{ height: 300, overflowY: 'auto', mb: 2 }} ref={chatWindowRef}>
            {messages.map((msg, index) => (
              <Message key={index} sender={msg.sender}>
                <ReactMarkdown>
                  {msg.text}
                </ReactMarkdown>
              </Message>
            ))}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <TextField
              fullWidth
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && sendMessage()}
              placeholder="Type your message here..."
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
            />
            <Button variant="contained" onClick={sendMessage}>
              Send
            </Button>
          </Box>
          {error && (
            <Typography variant="body2" sx={{ color: 'error.main', mt: 2 }}>
              {error}
            </Typography>
          )}
        </StyledBox>
      </Container>
    </Box>
  );
}

export default LangserveChat;
